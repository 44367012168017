import React from 'react';
import '../CSS/ContactUs.css'
import axios from 'axios';

export default class ContactUs extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            name:null,
            email:null,
            mobile:null,
            subject:null
        }
    }

    _submitForm = () =>{
        let email = this.state.email;
        let name = this.state.name;
        let mobile = this.state.mobile;
        let subject = this.state.subject;
        if(email === null || email.trim() === ""){
            alert("Email is required!!");
            return ;
        }
        if(mobile === null || mobile.trim() === ""){
            alert("Mobile number is required!!!.");
            return ;
        }
        if(subject === null || subject.trim() === ""){
            alert("Subject is required!!!.");
            return ;
        }
        let data = {
            email: email,
            name: name,
            mobile: mobile,
            subject: subject
        }
        axios.post("https://sharestock.io/api/oubMail/", data).then(response =>{
            console.log(response);
            alert("Your details has been saved successfully , we will connect with you asap");
        }).catch(error=>{
            console.log(error.message);
            alert(error.message);
        })
    }

    render() {
        return(
            <div className="container main-container" >
                <div className="row" align="center">
                    <h3 className="contact-us-text">Contact Us</h3>
                </div>
                <div className="row contact">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 span_1_of_3">
                        <div className="contact_info">
                            <h4>Find Us Here</h4>
                            <div className="map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d218.9455212627495!2d77.30021166688917!3d28.595926410632817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce48fce5cfd1b%3A0x63429fe374393255!2sChilla%20Shiv%20Mandir!5e0!3m2!1sen!2sin!4v1607103046492!5m2!1sen!2sin"
                                    width="300" height="200" frameBorder="0" style={{border:0}} allowFullScreen=""
                                    aria-hidden="false" tabIndex="0"></iframe>
                            </div>
                        </div>
                        <div className="company_address">
                            <h4>Company Information :</h4>
                            <h5>Delhi Office :</h5>

                            <p>Chilla Village, Mayur Vihar Phase 1 Extn.</p>
                            <p>India</p>
                            {/* <p>Phone:(+91) 9311559608</p> */}
                            <p>Email: <a href="mailto:oubelectronic@gmail.com">oubelectronic@gmail.com</a></p>
                            {/*<p>Follow on: <a href="#">Facebook</a>, <a href="#">Twitter</a></p>*/}
                            <br/>
                            <h5>Mumbai Office :</h5>
                            <p>Gajmohini Apartmant Manvel pada talav road, </p>
                            <p>Virar E. Thane Mumbai Maharastra 401303</p>
                            <p>India</p>
                            {/* <p>Phone:(+91) 9810194497</p> */}
                            <p>Email: <a href="mailto:info@oubelectronics.com">info@oubelectronics.com</a></p>
                            {/*<p>Follow on: <a href="#">Facebook</a>, <a href="#">Twitter</a></p>*/}
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 container-form">
                        <label htmlFor="name">NAME</label>
                        <input type="text" id="name" name="Name" placeholder="Your name.."  onChange={e => this.setState({name:e.target.value})}/>
                        <label htmlFor="email">EMAIL</label>
                        <input type="email" id="email" name="email" placeholder="Your email.." onChange={e => this.setState({email: e.target.value})} required={true}/>
                        <label htmlFor="mobile">MOBILE</label>
                        <input type="number" id="mobile" name="mobile" placeholder="Your mobile number.." onChange={ e =>{this.setState({mobile: e.target.value})}}/>
                        <label htmlFor="subject">SUBJECT</label>
                        <textarea id="subject" name="subject" placeholder="Write something.." style={{height: 200}} onChange={e => this.setState({subject: e.target.value})}/>
                        <input type="button" value="Submit" onClick={this._submitForm}/>
                    </div>
                </div>

            </div>
        )
    }


}