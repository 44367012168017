import React from 'react';
import '../CSS/Hompage.css'
import Card from "./Card";
import image from "../assets/img7.jpg";
import blacklock from '../assets/black.jpg';
import safe1 from '../assets/safe1.jpg';
import safe2 from '../assets/safe2.jpg';
import lift from  '../assets/lift.jpg';
import Software from '../assets/software.jpg';
import oub1 from    '../assets/oub1.jpeg';
import oub2 from    '../assets/oub2.jpeg';
import pannelLight from '../assets/export_image_5.jpg';
import encoder from '../assets/hotel-locking-encoder-2-246x300.jpg';
import lock from '../assets/hotel-key-card-system-ss.jpg';
import query_card from '../assets/query_card_1.png';
import energy from '../assets/energy_saver_1.png';

export default  class Homepage extends React.Component{

    constructor(props) {
        super(props);
        this.state ={
            option1:true,
            option2:false,
            option3:false,
            option4:false

        }
    }

    render() {
        return(
            <div className="home" >
                <div className="container-fluid slider" >
                    <div id="myCarousel" className="carousel slide" data-ride="carousel" style={{overflow:"hidden"}}>
                        <ol className="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                            <li data-target="#myCarousel" data-slide-to="1"></li>
                            <li data-target="#myCarousel" data-slide-to="2"></li>
                            <li data-target="#myCarousel" data-slide-to="3"></li>

                        </ol>
                        <div className="carousel-inner">
                        <div className="item active">
                                <img  className="img-responsive home-image" src="https://sc01.alicdn.com/kf/HTB1OXPxvrGYBuNjy0Foq6AiBFXaB/201557341/HTB1OXPxvrGYBuNjy0Foq6AiBFXaB.jpg" alt="Los Angeles" />
                                <div className="w3-display-middle w3-large w3-container w3-padding-16 w3-black">
                                    WELCOME TO OUB ELECTRONICS
                                </div>
                                <div className="carousel-caption">
                                    {/* <h3>RFID LOCKS</h3> */}
                                    {/*<p>Normal LED</p>*/}
                                </div>
                            </div>

                            <div className="item">
                                <img  className="img-responsive home-image" src="https://www.adellock-ksa.com/images/NileSlider201971373546.jpg" alt="Los Angeles" />
                                <div className="w3-display-middle w3-large w3-container w3-padding-16 w3-black">
                                    WELCOME TO OUB ELECTRONICS
                                </div>
                                <div className="carousel-caption">
                                    <h3>RFID LOCKS</h3>
                                    {/*<p>Normal LED</p>*/}
                                </div>
                            </div>
                            <div className="item">
                                <img className="img-responsive home-image" src="https://www.adellock-ksa.com/images/NileSlider20197137360.jpg" alt="Los Angeles" style={{width:"100%", height:600}}/>
                                <div className="w3-display-middle w3-large w3-container w3-padding-16 w3-black">
                                  HOTEL LOCKS
                                </div>
                                <div className="carousel-caption ">
                                    {/*<h3>HOME DECORATION LED</h3>*/}
                                    {/*<p>Normal LED</p>*/}
                                </div>
                            </div>

                        </div>
                        <a className="left carousel-control" href="#myCarousel" data-slide="prev">
                            <span className="glyphicon glyphicon-chevron-left"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="right carousel-control" href="#myCarousel" data-slide="next">
                            <span className="glyphicon glyphicon-chevron-right"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <div className="container-fluid card-container" >
                    <div className="row home-row lock">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card image={encoder}
                                  name={"HOTEL LOCK ENCODER"}
                                  price={"99"}
                                  prmotionalPrice={"200"}
                                  percentOff={"12%"}
                            />

                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card image={lock}
                                  name={"HOTEL LOCK"}
                                  price={"900"}
                                  prmotionalPrice={"1200"}
                                  percentOff={"25%"}

                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card image={query_card}
                                  name={"QUERY CARD"}
                                  price={"900"}
                                  prmotionalPrice={"1300"}
                                  percentOff={"31%"}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card image={safe2}
                                  name={"ENERGY SAVER"}
                                  price={"599"}
                                  prmotionalPrice={"899"}
                                  percentOff={"33%"}
                            />
                        </div>
                    </div>
                </div>
                <div className="container-fluid card-container" >
                    <div className="row home-row">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card image={blacklock}
                                  name={"HOTEL LOCK"}
                                  price={"99"}
                                  prmotionalPrice={"200"}
                                  percentOff={"12%"}        hacke
                            />

                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card image={lift}
                                  name={"LIFT CONTROLLER"}
                                  price={"900"}
                                  prmotionalPrice={"1200"}
                                  percentOff={"25%"}

                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card image={Software}
                                  name={"LOCK SOFTWARE"}
                                  price={"900"}
                                  prmotionalPrice={"1300"}
                                  percentOff={"31%"}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card image={safe1}
                                  name={"HOTEL SAFE LOCKER"}
                                  price={"599"}
                                  prmotionalPrice={"899"}
                                  percentOff={"33%"}
                            />
                        </div>
                    </div>
                </div>
                <div className="container-fluid card-container" >
                    <div className="row home-row" >
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card image={oub2}
                                  name={"ENERGY SAVER"}
                                  price={"360"}
                                  prmotionalPrice={"500"}
                                  percentOff={"28%"}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card
                                image={oub1}
                                name={"KEY CARD"}
                                price={""}
                                prmotionalPrice={""}
                                percentOff={""}
                            />
                        </div>
                       
                    </div>
                </div>
            </div>
        )
    }


}
