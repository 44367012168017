import  React from 'react';
import '../CSS/bottom.css'
import logo from "../assets/logo.png";

const  Bottom= () =>{

    return (<div>
        <footer>
            <div className="container-fluid footer-div">
                <div className="col-md-3 company-logo">
                    <a className="navbar-brand company" href="/">
                        <img className="img-responsive logo" src={logo}/>
                        OUB ELECTRONIC
                    </a>
                    <a className="navbar-brand company" href="/">
                        A Novel Syspro Pvt. Ltd. undertaking company
                    </a>
                </div>
                <div className="col-md-3 about-us">
                    <h3>About Us</h3>
                    <p>We are a tech company providing various category of Hotel Security System, Electronics lock and service for it.</p>
                </div>
                <div className="col-md-3 ftr-grid">
                    <h3>Contact Us</h3>
                    <ul className="nav-bottom ">
                        <li><a href="#">Mr. Chandan Mishra</a></li>
                        <li><a href="#"><span className="glyphicon glyphicon-envelope"><i> oubelectronic@gmail.com</i> </span></a></li>
                    </ul>
                    <ul className="nav-bottom ">
                        <li><a href="#">Mr. Aditya Jha </a></li>
                        <li><a href="#"><span className="glyphicon glyphicon-envelope"><i> info@oubelectronics.com</i> </span></a></li>
                    </ul>
                </div>
                <div className="col-md-3 ftr-grid">
                    <h3>Categories</h3>
                    <ul className="nav-bottom">
                        <li><a href="#">Panel Lights</a></li>
                        <li><a href="#">LED Lights</a></li>
                        <li><a href="#">Decorates</a></li>
                        <li><a href="#">Wall Lights</a></li>
                        <li><a href="#">Protectors</a></li>
                    </ul>
                </div>
            </div>
        </footer>
        <div className="copywrite">
            <div className="container">
                <div className="copy">
                    <p>Copyright © 2020 OUB Electronic. All Rights Reserved </p>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>)

}

export default Bottom;