import React from 'react';
import "../CSS/AboutUs.css"
export default class AboutUs extends React.Component{

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <section className="text-center about">
                <h1>About US</h1>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-ex-12 about-item wow lightSpeedIn" data-wow-offset="200">
                            <img src="https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"/>
                            <h2>Chandan Mishra</h2>
                            {/* <p><span className="glyphicon glyphicon-earphone"><i> 9311559608</i> </span></p> */}
                            <p><span className="glyphicon glyphicon-envelope"><i> oubelectronic@gmail.com</i> </span> </p>
                            <p>CoFounder</p>

                        </div>
                        {/*<div className="col-lg-4 col-sm-6 col-ex-12 about-item wow lightSpeedIn" data-wow-offset="200">*/}
                        {/*    <img src="https://scontent.fdel8-1.fna.fbcdn.net/v/t1.0-9/52698866_1946465112132776_8147166550894313472_n.jpg?_nc_cat=100&ccb=2&_nc_sid=09cbfe&_nc_ohc=Dur0nN4Oyu8AX9TnDjW&_nc_ht=scontent.fdel8-1.fna&oh=391340d99d255cdb1ae537a6f40ca41a&oe=5FEF2E97"/>*/}
                        {/*    <h2>Santosh Jha</h2>*/}
                        {/*    <p><span className="glyphicon glyphicon-earphone"><i> 9762731686</i> </span></p>*/}
                        {/*    <p><span className="glyphicon glyphicon-envelope"><i> info@novelsyspro.com</i> </span> </p>*/}
                        {/*    <p>CoFounder</p>*/}
                        {/*</div>*/}
                        <div className="clearfix visible-md-block visible-sm-block"></div>
                        <div className="col-lg-6 col-sm-6 col-ex-12 about-item wow lightSpeedIn" data-wow-offset="200">
                            <img src="https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg" />
                            <h2>Aditya Jha</h2>
                            {/* <p><span className="glyphicon glyphicon-earphone"><i> 9810194497</i> </span></p> */}
                            <p><span className="glyphicon glyphicon-envelope"><i> info@oubelectronics.com</i> </span> </p>
                            <p>Founder & CEO</p>
                        </div>

                    </div>

                </div>
            </section>
        )
    }


}